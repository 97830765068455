import styled from 'styled-components'
import { Row } from 'styled-bootstrap-grid'
import { media } from 'themes'

export const IconWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 16px;
    width: 16px;
    height: 1px;
    background-color: #D6D6D6;

    ${media.md`
      display: none;
    `}
  }
`
export const Icon = styled.img`
  ${media.md`
    margin-bottom: 32px;
  `}
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 24px;
`

export const Description = styled.p`
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;

  ${media.md`
    padding-right: 20px;
  `}

  ${media.sm`
    padding-right: 0;
  `}
`

export const Item = styled(Row)`
  margin-bottom: 50px;
`
