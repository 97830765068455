import styled from 'styled-components'
import { media } from 'themes'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 50px;

  ${media.md`
    overflow: hidden;
  `}
`

export const Title = styled.h1`
  font-family: 'Playfair Display';
  color: #f7f7f7;
  font-size: 128px;
  line-height: 128px;
  font-weight: bold;
  text-align: center;
  margin: 0;

  ${media.md`
    white-space: nowrap;
    width: 120%;
    margin-left: -10%;
  `}

  ${media.sm`
    display: none;
  `}
`

export const Subtitle = styled.h2`
  position: absolute;
  top: calc(50% + 15px); 
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Playfair Display';
  color: #000000;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  margin: 0;

  ${media.sm`
    position: static;
    top: auto;
    left: auto;
    transform: none;
    margin-top: 72px;
  `}
`
