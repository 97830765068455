import React from 'react'
import { Wrapper, Title, Subtitle } from './styled'

const PageTitle = ({ title, subtitle }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Wrapper>
)

export default PageTitle