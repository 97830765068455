import React from 'react'

import IconCivilRights from 'images/icon-civilRights.svg'
import IconEmploymentLaw from 'images/icon-employmentLaw.svg'
import IconLitigation from 'images/icon-litigation.svg'
import IconWhistleblower from 'images/icon-whistleblower.svg'

import { Container, Row, Col } from 'styled-bootstrap-grid'

import { IconWrapper, Icon, Item, Title, Description } from './styled'

const Content = () => (
  <Container>
    <Row>
      <Col md={6}>
        <Item>
          <Col lg={2}>
            <IconWrapper>
              <Icon src={IconWhistleblower} />
            </IconWrapper>
          </Col>
          <Col lg={9}>
            <Title>Whistleblower Law</Title>
            <Description>
              Our attorneys represent whistleblowers who uncover fraud in a
              multitude of industries and Government programs. Our attorneys
              have successfully represented whistleblowers in cases alleging
              Health Care fraud, such as Medicare, Medicaid, and other
              healthcare fraud, Government Contract fraud, such as in the
              construction and defense contractor industries, Pharmaceutical
              Industry fraud, Government Grant fraud, Securities fraud, and Tax
              fraud.
              <br />
              <br />
              We utilize the whistleblower programs that best suit each
              particular case including the federal and state False Claims Acts,
              and SEC, CFTC, FIRREA, and IRS whistleblower programs, Our firm
              handles all stages of a whistleblower representation, including
              all initial preparation for filing an action and disclosing a
              fraud scheme to the Government, aiding the Government in its
              investigation, and all stages of litigation, including taking a
              case to trial and negotiating settlements.
            </Description>
          </Col>
        </Item>
      </Col>
      <Col md={6}>
        <Item>
          <Col lg={2}>
            <IconWrapper>
              <Icon src={IconLitigation} />
            </IconWrapper>
          </Col>
          <Col lg={9}>
            <Title>Commercial Litigation</Title>
            <Description>
              Our attorneys are experienced in all types of commercial
              litigation in both state and federal court. We handle contract
              disputes, real estate litigation, fraud, and professional
              liability. We handle litigation in all stages through trial, as
              well as negotiate settlements and handle mediations.
            </Description>
          </Col>
        </Item>

        <Item>
          <Col lg={2}>
            <IconWrapper>
              <Icon src={IconEmploymentLaw} />
            </IconWrapper>
          </Col>
          <Col lg={9}>
            <Title>Employment Law</Title>
            <Description>
              We handle a variety of employment disputes, including those
              involving whistleblower retaliation. Our attorneys analyze each
              case individually to determine the appropriate plan of action,
              including everything from negotiation of severance packages to
              litigation.
            </Description>
          </Col>
        </Item>

        <Item>
          <Col lg={2}>
            <IconWrapper>
              <Icon src={IconCivilRights} />
            </IconWrapper>
          </Col>
          <Col lg={9}>
            <Title>Civil Rights</Title>
            <Description>
              We represent individuals who suffer from civil rights violations
              including violations of disability law and handle 1983 actions.
            </Description>
          </Col>
        </Item>
      </Col>
    </Row>
  </Container>
)

export default Content
